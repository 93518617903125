import { getParameterByName, getUpdatedUrlSignUp, removeURLParameter } from './qs';
export function backToLogin(loginHash = '#/lxyunLogin') {
  // 这里要处理返回uc登录还是励销云的登录, 移除uid字段
  let { search, pathname, origin } = window.location;
  let url = origin + pathname + search
  window.location = removeURLParameter(url, 'uid') + loginHash;
}

// 励销云的新的注册链接变动，需适配老的收藏链接到新的链接（0520励销云注册页搬到官网，再次更新, 为了使登录不受影响独立出来）
export function replaceFavouriteLinkIfNewArchSignUp(newParams = {}) {
  const appToken = getParameterByName('appToken');
  const brand = getParameterByName('brand');
  const registeredWay = getParameterByName('registered_way');
  const isLxApp = AppConf.productToken.some(app => app === appToken);
  // ik 收藏链接去UC注册页面，其他的去官网
  if (brand === 'ik' || registeredWay === 'normal_source') {
    return "unlock"
  }
  // 需求 12438 注册页面不再跳到官网的注册,走uc自己的注册
  if (window.location.hash === '#/signUp' && (isLxApp || appToken === AppConf.appToken.IK)) {
    return "unlock"
    // window.location = getUpdatedUrlSignUp({
    //   appToken: AppConf.appToken.IK,
    //   ...newParams,
    // });
  } else {
    return "unlock"
  }
}

