import { GET_CURRENT_USER_INFO_SUCCESS } from '../action-types/current-user';

const currentUser = (state = [], action) => {
  if (action.type === GET_CURRENT_USER_INFO_SUCCESS) {
    return action.data;
  }
  return state;
};

export default currentUser;
