/*
 * @Date: 2022-04-20 11:37:55
 * @LastEditors: huangl
 * @LastEditTime: 2022-05-06 22:16:35
 * @Description: file content
 * @FilePath: \user-center-frontend\src\sdks\wps\collectWps.js
 */
import catchStorage from "@/utils/catchStorage.js";

let changeSessionData = () => {
  let ssionData = new catchStorage(false);
  top.wps && top.wps.getSystemInfo({
    complete: (res) => {
      const dw_param = ssionData.getData("dw_param") || {};
      window.top.wps_systemInfo = res.data;
      dw_param._app_version = res.data.version
      dw_param._channel = res.data.channel
      ssionData.setData({ dw_param: dw_param });
    },
  });
}

if (window.dw?.setAppKey) {
  // crm html添加了dw 逻辑 有 dw 赋值 {}的逻辑
  dw.setAppKey("d2c1a458c5aa631b");
  dw.setDebug(["SX20220218IEHISC"].includes(AppConf.wpsAppId));
}
changeSessionData()
