import {
  GET_APPLICATION,
  GET_APPLICATION_ERROR,
  GET_APPLICATION_SUCCESS,
} from '../action-types/application';

import api from '../apis/sso';
import { changeFavicon } from '../utils/loading-animation';

export default function getAppInfo(body) {
  return {
    types: [GET_APPLICATION, GET_APPLICATION_SUCCESS, GET_APPLICATION_ERROR],
    callAPI: () => api.getAppInfo(body),
    handleData: (data) => {
      const { appKey, defaultLogoUrl } = data;
      const brandName = appKey.split('-')[0];
      const platformName = appKey.split('-')[1];
      // 如果是wps阻止切换logo
      if (defaultLogoUrl) {
        if(!(window.wps || window.wps_id)) {
          changeFavicon(data.defaultLogoUrl);
        }
      } // 根据应用修改favicon
      data.brand = brandName;
      data.platformName = platformName;
      data.isPlatformEdition = platformName === 'LX' || platformName === 'DING' || platformName === 'FS';  // LX 为企业微信版 DING 为钉钉版 IK 为独立版 1688 为阿里1688版 FS为飞书
      window.localStorage.setItem('appId', data.id);
      window.platformName = platformName
      return data;
    },
  };
}
