import { getCrmContext } from './platform';
import { getParameterByName } from './qs';
import LxLogo from '@assets/images/lx-logo.png'
import IkLogo from '@assets/images/ik-logo.png'
import WpsLogo from '@assets/images/wps/wps.png'
import { changeFaviconUrl } from './common';

function loadingAnimation() {
  document.addEventListener('DOMContentLoaded', () => {
    const loadingDom = document.querySelector('#loading');
    if (loadingDom) {
      loadingDom.style.display = 'none';
    }
  });
}

export function changeFavicon() {
  let registered_way = getParameterByName("registered_way")
  // 内嵌crm的情况，favicon由crm提供，uc不处理
  // 不再使用crm传递的值,前端默认展示
  const logoUrl = registered_way === "wps" ? WpsLogo : (getParameterByName("brand") === "ik" ? IkLogo : LxLogo)
  if (getCrmContext()) return;
  const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = logoUrl;
  document.getElementsByTagName('head')[0].appendChild(link);
}

export default loadingAnimation;
