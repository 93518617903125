import { message as antdMessage, notification } from 'antd';

antdMessage.config({ maxCount: 1, duration: 3, transitionName: 'move-right' });

// 处理请求错误
export function handleRequestError(notifyType = 'message') {
  return function (result, reqOpts) {
    const { message, success } = result;
    
    if (!success) {
      if (notifyType === 'notification') {
        notification.destroy();
        notification.warning({
          message: '操作提示',
          description: message,
        });
      } else {
        if (message !== 'TGT已过期' && message !== 'userToken过期或者不正确' && message !== '用户名或密码错误！你还有1次机会') {
          antdMessage.error(message);
        }
      }
    }

    return result;
  };
}

/**
 * @desc 清除输入左右两端的空格，
 * @param {Object} rawData 需要操作的数据
 * @param {Array} removeWhich 需要清除的特定key，默认为全部
 */
export function removeInputSpaces(rawData, removeWhich = 'all') {
  const keysArr = removeWhich === 'all' ? Object.keys(rawData) : removeWhich;

  keysArr.forEach((key) => {
    if (rawData[key]) {
      rawData[key] = typeof rawData[key] === 'string' || typeof rawData[key] === 'number'
        ? String(rawData[key]).trim()
        : rawData[key];
    }
  });

  return rawData;
}
