export const GET_COMPANY = 'GET_COMPANY';
export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS';
export const GET_COMPANY_ERROR = 'GET_COMPANY_ERROR';

export const GET_CREATOR_INFO = 'GET_CREATOR_INFO';
export const GET_CREATOR_INFO_SUCCESS = 'GET_CREATOR_INFO_SUCCESS';
export const GET_CREATOR_INFO_ERROR = 'GET_CREATOR_INFO_ERROR';

export const GET_COMPANY_LIST = 'GET_COMPANY_LIST';
export const GET_COMPANY_LIST_ERROR = 'GET_COMPANY_LIST_ERROR';
export const GET_COMPANY_LIST_SUCCESS = 'GET_COMPANY_LIST_SUCCESS';


export const GET_BRAND_INFO = 'GET_BRAND_INFO';
export const GET_BRAND_INFO_ERROR = 'GET_BRAND_INFO_ERROR';
export const GET_BRAND_INFO_SUCCESS = 'GET_BRAND_INFO_SUCCESS';


export const GET_LOGIN_BRANNER_INFO = 'GET_LOGIN_BRANNER_INFO';
export const GET_LOGIN_BRANNER_SUCCESS = 'GET_LOGIN_BRANNER_SUCCESS'
export const GET_LOGIN_BRANNER_ERROR = 'GET_LOGIN_BRANNER_ERROR';
