import React from 'react';
import asyncComp from 'core/lib/async-comp';
import { Switch, Route, HashRouter, Redirect } from 'react-router-dom';

const WpsLogin = asyncComp(() => import('../pages/wps/wps.login'))

export default class WpsRouter extends React.PureComponent {
  render() {
    return (
      <HashRouter>
        <Switch>
          <Route path="/" exact component={WpsLogin} />
            <Redirect to={"/"} />
        </Switch>
      </HashRouter>
    );
  }
}
