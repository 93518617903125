import api from "../../apis/sso";
import {getParameterByName} from "../../utils/qs";

// 进入这个逻辑说明uc判断登录成功但是跳转到其他产品模块如crm发现无法登录
// 切换产品，查询用户所在企业开通的模块，如果有crm则去crm，没有crm则去进销存, 都没有则退出
export default function SwitchProduct (appToken) {
  let url = `${AppConf.api.domain}${AppConf.publicPath}?appToken=${appToken}&uid=${getParameterByName('uid')}&type=logout`
  const brand = localStorage.getItem('brand')
  api.getModulesByTgc().then(res => {
    const {code, data} = res
    const modules = JSON.parse(data.modules)
    if (code === 0) {
      if (modules.some(item => item.module_type === 'crm_module')) {
        if(window.wps_id || window.wps && AppConf.CRMHandoverHost.wps) {
          url = AppConf.CRMHandoverHost.wps
        }
        else {
          url = brand === 'ik' ? AppConf.CRMHandoverHost.ik : AppConf.CRMHandoverHost.lx
        }
      } else if (modules.some(item => item.module_type === 'jxc_module')) {
        url = AppConf.jxcHost.ik
      }
    }
    window.location.href = url
  }).catch(() => {
    window.location.href = url
  })
}
