/**
 * @param {string} name
 * @param {boolean} [byHash=false] - 是否通过hash获取
 * @todo 支持传递下划线
 */
function getParameterByName(name, byHash = false) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${ name }=([^&#]*)`);
  let results = regex.exec(location[byHash ? 'hash' : 'search']);
  return results == null ? '' : decodeURIComponent(results[1]);
}

// 从url移除指定参数
export const removeURLParameter = (url, parameter) => {
  let urlParts = url.split('?');
  if (urlParts.length >= 2) {
    //参数名前缀
    let prefix = encodeURIComponent(parameter) + '=';
    let pars = urlParts[1].split(/[&;]/g);

    //循环查找匹配参数
    for (let i = pars.length; i-- > 0;) {
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        //存在则删除
        pars.splice(i, 1);
      }
    }
    return urlParts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
  }
  return url;
}

/**
 * @desc 将查询参数转化成字典
 */
function queryStringToMap() {
  const queryStringMap = new Map();

  window.location.search.replace(/[?&]+([^=&]+)=([^&#]*)/gi,
    (m, key, value) => {
      queryStringMap.set(key, value);
    }
  );

  return queryStringMap;
}

/**
 * @desc 将查询参数转化成Object
 */
function queryStringToParams() {
  const queryParams = {};

  window.location.search.replace(/[?&]+([^=&]+)=([^&#]*)/gi,
    (m, key, value) => {
      queryParams[key] = value === "null" ? "" : decodeURIComponent(value)
    }
  );

  return queryParams;
}

/**
 * @description 将Map转换为查询字符串
 */
function getSearchString(queryMap) {
  let searchString = '';
  queryMap.forEach((value, key) => {
    searchString = searchString ? `${searchString}&${key}=${value}` : `${key}=${value}`;
  });
  return searchString;
}

/**
 * 替换url参数：有则替换，没有则新增
 * @param newParams
 */
function getUpdatedUrl(newParams = {}, newHash) {
  // 地址为官网的注册进销存页面
  return AppConf.lixiaoyunHost + `/register?brand=lixiao&appType=ikjxc&appToken=${AppConf.appToken.DLJXC}`;
}

/**
 * 替换url参数：有则替换，没有则新增(0520注册搬到官网上，注册页)
 * @param newParams
 */
function getUpdatedUrlSignUp(newParams = {}) {
  const origin = AppConf.lixiaoyunHost;
  const paramMap = queryStringToMap();
  for(let key in newParams) {
    paramMap.set(key, newParams[key]);
  }
  // 地址为官网的注册页面
  return origin + `/register` + ('?' + getSearchString(paramMap));
}

/**
 * @desc 将appToken转换为请求headers中platform,与TGC这个cookie有关
 * @param {string} appToken 具体的应用Token
 */
function appTokenToPlatform(appToken) {
  const appTokenPlatformMap = new Map()
    .set('f6620ff6729345c8b6101174e695d0ab', 'IK') // 爱客CRM-非生产
    .set('a329426fa9d79d058d1d3812a7e8bdf2', 'IK') // 进销存独立版-非生产
    .set('a14cc8b00f84e64b438af540390531e4', 'IK')
    .set('6a99a826235f75de223cf5184caa0cc8', 'IK')
    .set('43ae7cad678c4664bfd9492ae437cb82', 'IK') // CRM励销独立版-非生产
    .set('3e1dbaaa3c184bd5a33ab5fbf162462a', 'IK')
    .set('766fcf9a46064513ac5bb7ae4af620f1', 'IK') // 励销搜客宝-非生产
    .set('e2a24777f5084cb8bd88d00c5edb4577', 'IK') // 励销搜客宝-生产
    .set('5e1cce3d8a3b4a76a414b10d2bfb667af', 'IK') // 励销机器人-非生产
    .set('267b9c009e5b407397f3bf9c5a2d623c', 'IK') // 励销机器人-生产
    .set('9de95972d5c3455ba16a6cf8f4872dd4', 'LX')
    .set('ace93093b51aa0e8f7a38a0fa37eaad7', 'LX')
    .set('2fc3b8d8828c85ca1cd5244af154c02c', 'LX')
    .set('ac2a065602491b9d247ea6d1d8f5a557', 'LX')
    .set('6f85c8d2540a48d08c634801f8648e9e', 'DING') // CRM钉钉版-非生产
    .set('2c8e775d76a90d9b066ec6d345df7c61', 'DING') // 进销存钉钉版-非生产
    .set('60ff3d7fd00c672c52a780b283bd738e', 'DING')
    .set('6733a8381e3e8e131397612a5e9095db', 'DING')
    .set('7ebb92aeeccf4554b475808668b8b53e', 'ALI1688') // 1688-非生产
    .set('cc5e5dbabc39440393ec32e9a0eaf60f', 'ALI1688') // 1688-生产
    .set("e7fea9637b984b57a654b890c96e40de", "FEISHU") // 飞书-非生产
    .set("2ba2a9bf5178445e974a4cf89241ddbb", "FEISHU") // 飞书-生产
    .set("7fc5ba42517b4b689f791ed90fe28f90", "QIDIAN"); // 企点-非生产
  return appTokenPlatformMap.get(appToken);
}

export {
  getParameterByName,
  queryStringToMap,
  appTokenToPlatform,
  getSearchString,
  getUpdatedUrl,
  getUpdatedUrlSignUp,
  queryStringToParams
};
