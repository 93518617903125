export default {
  key: {
    NOTLOGINSHOW: "登录页-未登录页面展示",
    NOTLOGINTOLOGIN: "登录页-未登录页点击立即登录",
    LOGINSUCCESS: "登录页-登录成功",
    AUTHORIZATIONSHOW: "授权-授权页面展示",
    AUTHORIZATIONAGREE: "授权-授权-同意按钮点击",
    AUTHORIZATIONSUCCESS: "授权-授权成功",
    AUTHORIZATIONNOTAGREE: "授权-授权-拒绝按钮点击",
    USERAGREEMENTSHOW: "用户协议-用户协议页面展示",
    USERAGREEMENTAGREE: "用户协议-用户协议按钮-同意按钮点击",
    USERLISTSHOW: "设置-成员管理页面展示",
    INVITECLICK: "设置-点击邀请成员",
    INVITESHOW: "设置-邀请成员弹窗展示",
    AUDITTIP: "设置-名额不足弹窗提示",
    COMPANYSHOW: "设置-公司信息页面展示",
    EXPIRESHOW: "购买-已到期弹窗展示",
    EXPIRECLICK: "购买-已到期弹窗点击立即购买",
    RIGHTBUY: "购买-首页-购买产品点击",
    RIGHTBUYMEMBER: "购买-成员管理-购买产品点击",
    USERLEVELUP: "购买-成员管理-立即升级按钮点击",
    USERLEVELUPHOME: "购买-首页-立即升级按钮点击",
    USERRENEW: "购买-成员管理-续费时长按钮点击",
    USERRENEWHOME: "购买-首页-续费时长按钮点击"
  },
  NOTLOGINSHOW: {
    page: "unlogin",
    action: "show",
    app: "pc",
  }, //未登录页面展示
  NOTLOGINTOLOGIN: {
    page: "unlogin",
    element: "login",
    action: "click",
    app: "pc",
  }, //未登录页点击立即登录
  LOGINSUCCESS: {
    page: "unlogin",
    element: "login",
    action: "success",
    app: "pc",
  }, //登录成功
  AUTHORIZATIONSHOW: {
    page: "authorize",
    action: "show",
    app: "pc",
  }, //授权页面展示
  AUTHORIZATIONAGREE: {
    page: "authorize",
    element: "agree",
    action: "click",
    app: "pc",
  }, //授权-同意按钮点击
  AUTHORIZATIONSUCCESS: {
    page: "authorize",
    element: "agree",
    action: "success",
    app: "pc",
  }, //授权成功
  AUTHORIZATIONNOTAGREE: {
    page: "authorize",
    element: "refuse",
    action: "click",
    app: "pc",
  }, //授权-拒绝按钮点击
  USERAGREEMENTSHOW: {
    page: "protocol",
    element: "agree",
    action: "show",
    app: "pc",
  }, //用户协议页面展示
  USERAGREEMENTAGREE: {
    page: "protocol",
    element: "refuse",
    action: "click",
    app: "pc",
  }, //用户协议按钮-同意按钮点击
  USERLISTSHOW: {
    page: "member",
    element: "",
    action: "show",
    app: "pc",
    polaris: "", // 当前团队已审核成员个数
  }, //成员管理页面展示
  INVITECLICK: {
    page: "invite",
    element: "invite",
    action: "click",
    app: "pc",
  }, //点击邀请成员
  INVITESHOW: {
    page: "invite",
    element: "invite",
    action: "show",
    app: "pc",
  }, //邀请成员弹窗展示
  AUDITTIP: {
    page: "audit",
    element: "",
    action: "show",
    module: "window",
    app: "pc",
    func: "setting",
  }, //名额不足弹窗提示
  COMPANYSHOW: {
    page: "",
    element: "information",
    action: "show",
    app: "pc",
  }, //公司信息页面展示
  EXPIRESHOW: {
    page: "expire",
    element: "",
    action: "show",
    module: "window",
    app: "pc",
    func: "purchase",
  }, //已到期弹窗展示
  EXPIRECLICK: {
    page: "expire",
    element: "",
    action: "click",
    module: "window",
    app: "pc",
    func: "purchase",
  }, //已到期弹窗点击立即购买
  USERLEVELUP: {
    page: "member",
    element: "purchase_button",
    action: "click",
    module: "upgrade",
    app: "pc",
    func: "purchase",
  }, //成员管理-立即升级按钮点击
  USERRENEW: {
    page: "member",
    element: "purchase_button",
    action: "click",
    module: "renew",
    app: "pc",
    func: "purchase",
  }, // 购买-成员管理-续费时长按钮点击
  RIGHTBUYMEMBER: {
    page: "member",
    element: "",
    action: "click",
    module: "window",
    app: "pc",
    func: "purchase",
  }, //购买-成员管理-购买按钮点击
  USERRENEWHOME: {
    page: "buy",
    element: "purchase_button",
    action: "click",
    module: "renew",
    app: "pc",
    func: "purchase",
  }, //左上角-续费时长点击
  USERLEVELUPHOME: {
    page: "buy",
    element: "purchase_button",
    action: "click",
    module: "upgrade",
    app: "pc",
    func: "purchase",
  }, //左上角-升级工号点击
  RIGHTBUY: {
    page: "buy",
    element: "purchase_button",
    action: "click",
    module: "",
    app: "pc",
    func: "purchase",
  }, //购买-首页-购买按钮点击
};
