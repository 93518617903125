import UserCenterApiRequest from './api-request';
import { getParameterByName, appTokenToPlatform } from '../utils/qs';
import utils from '../utils';

const api = new UserCenterApiRequest();

// 判断是否登录 & 获取ticket & 获取userToken
function checkLoginState(params) {
  return api.get('sso/checkLoginState', {
    params,
    interceptor: {
      responseAfter: (result) => {
        if (result.code === 0) {
          const { data: { userToken } } = result;
          if (userToken !== 'null') {
            window.sessionStorage.setItem('userToken', userToken);
          }
        }
        return result;
      },
    },
  });
}

// 创造iframe 实现跨域
function createIframe(urls, type = 'crossLogin', uid, platform = null) {
  const paramsName = type === 'crossLogin' ? 'loginUrl' : 'logoutUrl';
  urls.forEach((url) => {
    const iframe = document.createElement('iframe');
    const iframeSrc = type === 'crossLogin'
      ? `${window.AppConf.api.domain}${window.AppConf.api.pathPrefix}/sso/${type}?${paramsName}=${url}&platform=${platform || appTokenToPlatform(getParameterByName('appToken'))}`
      : `${window.AppConf.api.domain}${window.AppConf.api.pathPrefix}/sso/${type}?${paramsName}=${url}&uid=${uid}&platform=${platform || appTokenToPlatform(getParameterByName('appToken'))}`;

    iframe.width = 0;
    iframe.height = 0;
    iframe.style.display = 'none';
    iframe.src = iframeSrc;
    document.body.appendChild(iframe);
  });
  return new Promise((resolve) => {
    if(window.wps_id || window.wps) {
      resolve()
      return
    }
    setTimeout(resolve, 1000);
  }); // 没有真正解决如何判断所有应用是否登录上的问题，使用延迟降低出现的概率
}

// 获取来源应用信息
function getAppInfo(params) {
  const appToken = getParameterByName('appToken');
  // 如果没有token就不请求信息
  if (appToken) {
    const p = { ...params };
    // 添加产品新架构类型参数
    if ((location.hash === utils.Constants.loginHash.LX || utils.isModuleArchType()) && getParameterByName('brand') !== 'ik') {
      if (getParameterByName('appToken') !== AppConf.appToken.IK) {
        p.archType = utils.Constants.moduleArchType;
      }
    }
    return api.get('sso/getApp', { params: p });
  }
  return Promise.resolve('no token');
}

// 登录
function singleSignOn(body, appToken) {
  var headers = {};
  if (appToken) {
    headers.appToken = appToken;
    headers.platform = appTokenToPlatform(appToken);
  }
  return api.post('sso/login', { body, headers });
}

// 千帆登录
function singleSignOnByQF(params, appToken) {
  let headers = { appToken: appToken || getParameterByName('appToken') }
  return api.get('/api/qianfan/freeLogin', { params });
}

// 退出
function singleSignOut(body) {
  let headers = {
    appToken: getParameterByName('oldAppToken') ? getParameterByName('oldAppToken') : getParameterByName('appToken'),
    platform: getParameterByName('platform') || (getParameterByName('oldAppToken') ? appTokenToPlatform(getParameterByName('oldAppToken')) : appTokenToPlatform(getParameterByName('appToken'))),
  };
  return api.post('sso/logout', { body, headers });
}

// 获取该用户拥有的应用列表
function getAppUrls(body, appToken) {
  let headers = {};
  if (appToken) headers.appToken = appToken;
  return api.post('sso/getAppUrls', { headers, body });
}

// 单点登录2.0
// 有token表示，励销云选择应用登录
async function asyncSingleSignOn(body, appToken) {
  const signInResult = await singleSignOn(body, appToken);
  if (signInResult.code !== 0) {
    return signInResult;
  }else if(signInResult?.data?.accountToken && signInResult?.data?.confirmedAt == false){
    return signInResult;
  }
  localStorage.setItem('brand', signInResult?.data?.brand?.brand)
  const urls = await getAppUrls({
    platform: signInResult.data.platform || appTokenToPlatform(getParameterByName('appToken')),
    noticeType: signInResult.redirectState === 0 ? 'all' : 'other',
    uid: signInResult.data.uid,
    type: body.type,
  }, appToken);

  await createIframe(urls.data, 'crossLogin', null, signInResult.data.platform, appToken);

  // 并发请求应用登录
  // urls.data.map(async (url) => {
  //   await crossLogin({ loginUrl: url });
  // });

  return signInResult;
}

// 通知其他应用登录
async function noticeLogin(body) {
  const urls = await getAppUrls({ noticeType: body.noticeType, uid: body.uid, type: body.type, platform: body.platform });
  await createIframe(urls.data, 'crossLogin', null, body.platform);
  return urls;
}

// 单点退出
async function asyncSingleSignOut(body, appToken) {
  const urls = await getAppUrls(body, appToken);
  const signOutResult = await singleSignOut();

  if (signOutResult.code !== 0) {
    return signOutResult;
  }

  await createIframe(urls.data, 'crossLogout', body.uid);

  return signOutResult;
}

// 获取励销云产品应用
function getLxYunApps() {
  return api.get('sso/getLxYunApps');
}

// 通过tgc获取用户模块信息
function getModulesByTgc(params) {
  return api.get('uc/front/getLxyPackage')
}

function getBrand(params) {
  return api.get('/api/brand/getBrand', { params });
}

function getUserPhone(params) {
  return api.get('/api/external/auth/getPhoneByAccountToken', { params })
}


// 钉钉客户端免登接口
function getUserInfoByCode(params) {
  return api.get('/api/ding/getUserInfoByCode', { params })
}

// 重置密码
function postResetPasswordFirstLogin(body) {
  return api.post('/api/user/management/resetPasswordFirstLogin', { body })
}

// 查询是否OEM
function queryIsOEM(params) {
  return api.get('/api/uc/resource/getOemResource', { params })
}

export default {
  getAppInfo,
  checkLoginState,
  asyncSingleSignOn,
  noticeLogin,
  asyncSingleSignOut,
  singleSignOut,
  getLxYunApps,
  getModulesByTgc,
  getBrand,
  getUserPhone,
  singleSignOnByQF,
  getUserInfoByCode,
  postResetPasswordFirstLogin,
  queryIsOEM,
};
