class catchStorage {
  constructor(isLocal) {
    this.data = isLocal ? window.localStorage : window.sessionStorage;
  }
  getData(key) {
    if (typeof key === "string") {
      return JSON.parse(this.data.getItem(key) || null)
    } else {
      if (Array.isArray(key)) {
        const rest = key.map((item) => JSON.parse(this.data.getItem(item) || "null"));
        return rest;
      } else {
        return "";
      }
    }
  }
  setData(params) {
    const keys = Reflect.ownKeys(params);
    keys.forEach((item) => {
      const itemData = params?.[item];
      if (typeof itemData === "string") {
        this.data.setItem(item, itemData);
      } else {
        this.data.setItem(item, JSON.stringify(itemData));
      }
    });
  }
  removeData(key) {
    if (typeof key === "string") {
      this.data.removeItem(key);
    } else if (Array.isArray(key)) {
      key.forEach((item) => this.data.removeItem(item));
    }
  }
  clear() {
    this.data.clear();
  }
}

export default catchStorage;
