/*
 * @Date: 2022-03-07 17:31:56
 * @LastEditors: huangl
 * @LastEditTime: 2022-03-18 17:18:25
 * @Description: file content
 * @FilePath: \user-center-frontend\src\components\widgets\SwitchEnterprise.js
 */
import api from '../../apis/sso';
import { backToLogin } from '../../utils/lxyun';
import utils from '../../utils';
import {getParameterByName} from "../../utils/qs";
import { getWpsAppid } from '@/utils/wps';

export default class SwitchEnterprise {

  // 切换企业
  static switch(newOid, oldOid, uid, accountToken, opts = {}) {
    if (newOid !== oldOid) {
      const signOutCallback = (res = {}) => {
        const signInBody = {
          oid: newOid,
          type: 'login',
          noticeType: 'all',
          loginType: 5, // 5 切换企业登录
          accountToken
        };
        let appToken = getParameterByName('appToken') || '';
        api.asyncSingleSignOn(signInBody, appToken).then((res) => {
          if (res.success) {
            opts.onSuccess && opts.onSuccess(res);
          } else {
            opts.onFail && opts.onFail();
          }
          opts.onComplete && opts.onComplete();
        });
      };
      SwitchEnterprise.signOut(uid, signOutCallback);
    }
  }

  // 退出登录
  // 默认：退出后跳登录页面
  static signOut(uid, signOutCallback, noticeType = 'all') {
    const asyncSingleSignOutBody = { uid, type: 'logout', noticeType };
    let appToken;
    if (getParameterByName('oldAppToken')) {   // oldAppToken 用来处理1688切换企业的问题
      appToken = getParameterByName('oldAppToken');
    }
    if (!signOutCallback) {
      if(window.wps || window.wps_id) {
        // wps跳到默认页
        window.top.location.href = `${StaticHost}/web/wps.html?appid=${getWpsAppid()}&appToken=${AppConf?.appToken?.IK}#/`
        return
      }
      signOutCallback = () => backToLogin(`#/lxyunLogin`);
    }

    api.asyncSingleSignOut(asyncSingleSignOutBody, appToken).then((res) => {
      if (res.code === 0) {
        utils.clearLoginData();
        signOutCallback(res.data);
      }
    });
  }
}

window.SwitchEnterprise = SwitchEnterprise
