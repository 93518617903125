import configureStore from 'core/lib/store';
import reducerRegistry from 'core/lib/store/reducer-registry';

import { companyInfo, creatorInfo, companyList, brandInfo, LoginBrannerInfo } from './company';
import currentUserReducer from './current-user';
import applicationReducer from './application';
import getAppInfo from '../actions/application';

reducerRegistry.register('company', companyInfo);
reducerRegistry.register('creator', creatorInfo);
reducerRegistry.register('brand', brandInfo);
reducerRegistry.register('companyList', companyList);
reducerRegistry.register('currentUser', currentUserReducer);
reducerRegistry.register('application', applicationReducer);
reducerRegistry.register('loginBrannerInfo', LoginBrannerInfo);

const userCenterStore = configureStore();

userCenterStore.dispatch(getAppInfo()); // 发送获取登录应用信息的action

export default userCenterStore;
