import React, { useState, useEffect} from 'react';
import asyncComp from 'core/lib/async-comp';
import { Switch, Route, HashRouter } from 'react-router-dom';

import api from '../apis/sso';
import docCookies from '../utils/cookies';
import {queryStringToMap, appTokenToPlatform, getParameterByName, getSearchString} from '../utils/qs';
import { getCrmContext } from '../utils/platform';
import utils from '../utils';
import SwitchEnterprise from '../components/widgets/SwitchEnterprise';
import SwitchProduct from '../components/widgets/SwitchProduct';
import {replaceFavouriteLinkIfNewArchSignUp} from "../utils/lxyun";

const LoggedRoute = asyncComp(() => import('./Logged'));
const ErrorPage = asyncComp(() => import('../pages/error'));
const FeishuLogIn = asyncComp(() => import('../pages/feishu-login'));
const UnAuthError = asyncComp(() => import('../pages/error/UnAuthError'));
const LoginException = asyncComp(() => import('../pages/error/LoginException'));
const Login = asyncComp(() => import('../pages/login'));
const NotLoggedRoute = asyncComp(() => import('./NotLogged'));
const AdapterRoute = asyncComp(() => import('./Adapter'))
const SwitchRoute = asyncComp(() => import('./SwitchEnterprise'))
const Invite = asyncComp(() => import('../pages/h5-invite/invite'))
const LoginSkb = asyncComp(() => import('../pages/login/skb'));


import PushToInvite from '../components/widgets/Invite'
import { changeFaviconUrl } from '@/utils/common';
import { installWps } from '@/utils/wps';

const IsLogin = ({redirectUri}) => {
  const [isLogin, setIsLogin] = useState(true) 
  useEffect( () => {
    const params = {};
    if (utils.getLoginSource()) {
      params.loginSource = utils.getLoginSource();
    }
    console.log('IsLogin',IsLogin);
    api.checkLoginState(params).then((res) => {
      console.log('api.checkLoginState(params).then.res',res);
      if (res.code === 0) {
        const { data: { ticket, platform = '', brand = {} } } = res;
        let jumpUrl = redirectUri;
        if(res.data.pageHost) {
          jumpUrl = res.data.pageHost;
        }
        let Symbols = jumpUrl?.includes("continue_url") ? "&" : "?"
        localStorage.setItem('brand', brand.brand)
        window.location = decodeURIComponent(`${jumpUrl}${Symbols}st=${ticket}&platform=${platform}`);
      } else if(res.code === 200035) {
        setIsLogin(false)
      } else {
        utils.clearLoginData();
        window.location.reload();
      }
    });
  }, [])
  if(!isLogin){
    return <NotLoggedRoute />
  } else {
    return null;
  }
}

export default class UserCenterRouter extends React.PureComponent {
  accessControl = () => {
    // 防止免密登录过长的默认logo展示 仍会有闪烁现象
    installWps(() => {
      changeFaviconUrl("wps", "")
    })
    /** @desc Map 字段解释
     * redirectUri           -- 最终要回到redirectUri处，是否直接回看登录与否
     * appToken              -- 表示应用
     * ticket                -- 与uid一起表示扫码成功跳转而来
     * type                  -- type=logout，与uid一起表示需要登出, type=switch 与uid一起表示需要切换产品
     * uid                   -- 唯一用户标示
     * TGC{platform}         -- 存储在cookie中表示已经登录过
     * accountToken          -- 切换企业的请求，accountToken用来调用登录
     * @todo 将widow.location.href 这种跳转进行抽象封装
     */
    const paramsMap = queryStringToMap();
    const { search, pathname, origin } = window.location;
    const [hasUid, hasTicket, hasRedirectUri] = [paramsMap.has('uid'), paramsMap.has('ticket'), paramsMap.has('redirectUri')];
    const [uid, redirectUri, ticket, type, appToken, platform, registered_way, accountToken,] =
      [
        paramsMap.get('uid'),
        paramsMap.get('redirectUri'),
        paramsMap.get('ticket'),
        paramsMap.get('type'),
        paramsMap.get('appToken'),
        paramsMap.get('platform'),
        paramsMap.get('registered_way'),
        paramsMap.get('accountToken')
      ];
    const originPlatform = paramsMap.get('originPlatform')
    const Logged = window.localStorage.getItem('backdoor') === '1206' || getCrmContext()
      || docCookies.getItem(`TGC${platform || appTokenToPlatform(appToken)}`);
    // 是否需要跳到未登录的路由 目前注册 体验需要
    const showNotLoggedUrls = ['#/signUp', '#/tryout', '#/lxyunResetPw', '#/create', '#/buy']
    // const showNotLoggedUrls = ['#/signUp', '#/tryout', '#/lxyunResetPw', '#/create', '#/buy', '#/lxyunLogin', '#/company']
    const isShouldShowNotLogged = showNotLoggedUrls.includes(location.hash)

    // 移动端h5邀请页面
    if (getParameterByName('inviteId')){
      return <PushToInvite />
    }
    if ((Logged && window.TGTCODE !== 200049) || accountToken) {
      // 通过ticket登录，目前有1688，360，企业微信扫码
      if (hasUid && hasTicket) {
        if (registered_way === 'ALI1688') {
          window.location = `${redirectUri}?st=${ticket}`
        } else if (registered_way) {
          // wps不需要调用crossLogin接口
          if (registered_way === 'wps' || registered_way === 'skb') {
            changeFaviconUrl("wps", "")
            // 如果是wps,需要把url所有参数拼上
            let queryMap = queryStringToMap()
            queryMap.delete("ticket")
            let searchUrl = getSearchString(queryMap)
            window.location = `${redirectUri}?st=${ticket}&${searchUrl}`
            return
          }
          // noticeType: all是通知用户的企业下所有应用上线下线，other是通知除自己外的应用上线下线
          const queryBody = { type: 'login', uid, noticeType: 'all', platform: platform };
          const localHomepage = window.AppConf.jumpToEnterpriseWeChatAppToken.find(item => item[0] === appToken)[1];
          // 通知其他应用上线
          api.noticeLogin(queryBody).then((res) => {
            if (res.code === 0) {
              let Symbols = redirectUri?.includes("continue_url") ? "&" : "?"
              window.location = hasRedirectUri ? `${redirectUri}${Symbols}st=${ticket}` : `${localHomepage}${Symbols}st=${ticket}`;
            }
          });
        } else {
          // 第三方（企业微信或者飞书）扫码成功
          const queryBody = { type: 'login', uid, noticeType: 'other', platform: 'LX' };
          const localHomepage = window.AppConf.jumpToEnterpriseWeChatAppToken.find(item => item[0] === appToken)[1];

          if (!window.localStorage.getItem('ik_uc_uid')) {
            window.localStorage.setItem('ik_uc_uid', uid);
          }
          api.noticeLogin(queryBody).then((res) => {
            if (res.code === 0) {
              window.location = hasRedirectUri ? `${redirectUri}?st=${ticket}&appToken=${appToken}` : `${localHomepage}?st=${ticket}&platform=${platform}&appToken=${appToken}`;
            }
          });
        }
      } else if (hasUid && type === 'logout') { // crm、进销存退出登录
        SwitchEnterprise.signOut(uid, null, 'other');
        // 1.uc已登录的情况下，crm跳到uc; 2.只有新版crm才进入对应主页
      } else if (hasUid && type === 'switch') {
        // 这种情况目前是uc登录了，但是产品端没有开通信息，重定向回来
        // 这里通过接口判断去哪个工作台
        SwitchProduct(appToken)
      } else if (hasRedirectUri) {
        // 因新架构和老爱客的appToken合并，需加登录来源来区分
        if(isShouldShowNotLogged) {
          return <NotLoggedRoute />
        } else {
          return <IsLogin redirectUri={redirectUri} />
        }
      } else if (accountToken) {
        const { hash } = window.location
        if(hash?.includes('thirdLoginVerify')) {
          // 千帆营销通的登录验证页面
          return <NotLoggedRoute />
        }
        // 携带accountToken 表示这是一次切换企业的操作
        return <SwitchRoute />
      } else {
        // 是否是体验注册页面 是的话跳到未登录路由
        return isShouldShowNotLogged ? <NotLoggedRoute /> : <LoggedRoute />;
      }
    } else {
      // 注册重定向去官网
      let lock = true;
      if (replaceFavouriteLinkIfNewArchSignUp({
        archType: utils.Constants.moduleArchType,
      }) === 'unlock') {
        lock = false
      }
      // 360 试用/登录
      if (Number(originPlatform) === 360 || originPlatform === "wps" || originPlatform === 'skb') {
        originPlatform === "wps" && changeFaviconUrl("wps", "")
        return <AdapterRoute />
      }
      if(appToken == window.AppConf.appToken.FEISHU){ // 飞书二维码登录页
        const { redirectHost, clientId, appToken, CRMHandoverHost } = window.AppConf;
        window.location =  `https://passport.feishu.cn/suite/passport/oauth/authorize?client_id=${clientId}&redirect_uri=${encodeURIComponent(`${redirectHost}/api/fs/${clientId}/qrLoginCallback?successUrl=${encodeURIComponent(`${redirectHost}/web/index.html?appToken=${appToken.FEISHU}&redirectUri=${CRMHandoverHost['lx']}`)}`)}&response_type=code&state=STATE`;
      } else if (window.AppConf.jumpToEnterpriseWeChatAppToken.some(app => app[0] === appToken)) {// 企业微信二维码登录页
        const brand = window.AppConf.jumpToEnterpriseWeChatAppToken.find(app => app[0] === appToken)[2];
        window.location = `${window.AppConf.jumpToWhere[brand]}/${appToken}/ssoLogin?successUrl=${encodeURIComponent(origin + pathname + search)}`;
        return;
      }

      // 体验企业免登录
      if (paramsMap.has('secure_token') && paramsMap.has('oid')) {
        const reqBody = {
          secureToken: paramsMap.get('secure_token'),
          oid: paramsMap.get('oid'),
          type: 'login',
          noticeType: 'other',
          loginType: 4
        };
        api.asyncSingleSignOn(reqBody, appToken).then((res) => {
          if (res.success) {
            const jumpUrl = redirectUri || (window.wps_id || window.wps && AppConf.CRMHandoverHost.wps) ? AppConf.CRMHandoverHost.wps : window.AppConf.CRMHandoverHost.lx;
            utils.loginSuccessCallback(res.data, appToken, 1, jumpUrl);
          }
        });
        return;
      }

      if (!lock) {
        return <NotLoggedRoute />;
      } else {
        return null
      }
    }
  }

  assignRoute = () => (<>{this.accessControl()}</>)

  render() {
    return (
      <HashRouter>
        <Switch>
          <Route path="/feishuLogin" exact component={FeishuLogIn} />
          <Route path="/error" exact component={ErrorPage} /> {/* 这种路由数量多了抽离成other路由 */}
          <Route path="/unauth" exact component={UnAuthError} /> {/* 这种路由数量多了抽离成other路由 */}
          <Route path="/loginException" exact component={LoginException} />
          <Route path="/createskb" exact component={LoginSkb} />
          {/* <Route path="/dingLogin" exact component={DingdingLogin} /> */}
          {/* <Route path="/dingding" exact component={DingdingHome} /> */}
          {/* <Route path="/lxyunLogin" exact component={Login} /> */}
          {/*h5移动端邀请页面*/}
          <Route path="/invite" exact component={Invite} />
          <Route path="/" render={this.assignRoute} />
        </Switch>
      </HashRouter>
    );
  }
}
