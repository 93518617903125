import { GET_COMPANY_SUCCESS } from '../action-types/company';
import { GET_APPLICATION_SUCCESS } from '../action-types/application';

const applicationInfo = (state = {}, action) => {
  if (action.type === GET_APPLICATION_SUCCESS) {
    return action.data;
  }

  if (action.type === GET_COMPANY_SUCCESS) {
    const stateCopy = Object.assign({}, state);

    if (!state.platformName) {
      const { data: { platformType } } = action;
      stateCopy.platformName = platformType;
      stateCopy.isPlatformEdition = platformType === 'LX' || platformType === 'DING';
    }
    return stateCopy;
  }

  return state;
};

export default applicationInfo;
