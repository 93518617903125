import { getParameterByName, appTokenToPlatform } from './qs';
import { isJxc } from './platform';
import docCookies from './cookies';


const Constants = {
  errorCode: {
    MODULE_UN_AUTH: 200089,
    LOGIN_NOT_MATCH: 200098, // 登录入口与企业架构不匹配
    IK_TRANSFER_NEW_ARCH_LOGIN_ERROR: 200099, // 爱客迁移到励销用户在老登录界面登录异常的code
    TGT_EXPIRED: 200249,
    GT_ERROR: 200140,  // 极验错误
    QR_LOGIN_CODE_NOT_EXIT:200176,//二维码已过期
    QR_LOGIN_CODE_ERROR:200177,//二维码code无效
  },
  lxyLogo: 'https://lixiaoyun-files.lixiaoyun.com/lixiaoyun--LX.png',
  ikLogo: 'https://lixiaoyun-files.lixiaoyun.com/lixiaoyun--IK.png',
  loginSource: {
    IK: 'ik',
    LX: 'lx',
  },
  signUpHash: '#/signUp',
  loginHash: {
    IK: '#/login',
    LX: '#/lxyunLogin',
  },
  moduleArchType: 'productAndModule', // 主产品+增值模块
  oldIkCrmArchType: 'origin', // 原始架构
};

function walkTree(parent, cb) {
  cb(parent);

  const children = parent.departmentTreeVOList;
  if (children.length > 0) {
    children.forEach((node) => {
      walkTree(node, cb);
    });
  }
}

function formatLongText(source = '', maxLetter) {
  if (source.length <= maxLetter) return [source, false];
  const slicedText = source.slice(0, maxLetter - 1) + '...';
  return [slicedText, true];
}

// 是否是主产品+模块架构，目前只有励销云是这个架构
// 1 - 原始架构 2 - 多产品 3 - 主产品+增值模块
function isModuleArchType(archType) {
  if (!archType) {
    archType = getParameterByName('archType');
  }
  return archType === Constants.moduleArchType && !isJxc();
}

// 根据架构类型，获取跳转到具体的登录页面
function getLoginPageByArchType(archType) {
  return isModuleArchType(archType) ? '/lxyunLogin' : '/login';
}

function loginSuccessCallback(userInfo, appToken, isLxyunApp, redirectUri) {
  const { ticket, uid, platform, xLxGid } = userInfo;
  if (!window.localStorage.getItem('ik_uc_uid')) {
    window.localStorage.setItem('ik_uc_uid', uid);
  }
  if (window.location.host === 'localhost:1206') {
    window.localStorage.setItem('backdoor', 1206);
  }
  let state = getParameterByName("state")
  let registerWay = getParameterByName("registerWay")
  let realRedirectUri = (getParameterByName("redirectUri") && registerWay === "normal_source") ? decodeURIComponent(getParameterByName("redirectUri")) : redirectUri
  let Symbols = realRedirectUri?.includes("continue_url") ? "&" : "?"
  window.localStorage.setItem('isLxyunApp', isLxyunApp);
  window.location = `${realRedirectUri}${Symbols}st=${ticket}&platform=${platform}&appToken=${appToken}&state=${state}&x-lx-gid=${xLxGid}`;
}

function clearLoginData() {
  window.sessionStorage.removeItem('userToken');
  window.localStorage.removeItem('ik_uc_uid');
  window.localStorage.removeItem('backdoor');
  window.localStorage.removeItem('isLxyunApp');
  docCookies.removeItem(`TGC${getParameterByName('platform') || appTokenToPlatform(getParameterByName('appToken'))}`, '/', '.weiwenjia.com');
  docCookies.removeItem('archType', '/', '.weiwenjia.com');
}

// 老爱客和新架构获取重定向url
function getRedirectUrlForIkAndNewArch() {
  let appToken = getParameterByName('appToken')
  if (appToken === AppConf.appToken.IK || appToken === AppConf.appToken.CRM1688) { // 新架构合并appToken的情况
    if (docCookies.getItem('archType') === Constants.moduleArchType) { // 新架构跳励销crm，否则跳爱客crm
      return (window.wps_id || window.wps && AppConf.CRMHandoverHost.wps) ? AppConf.CRMHandoverHost.wps : AppConf.CRMHandoverHost.lx;
    }
    return (window.wps_id || window.wps && AppConf.CRMHandoverHost.wps) ? AppConf.CRMHandoverHost.wps : AppConf.CRMHandoverHost.ik;
  }
}

// 登录和注册链接区分登录来源
function getLoginSource() {
  const hs = location.hash;
  // 注册
  if (hs === Constants.signUpHash) {
    return getParameterByName('archType') === Constants.moduleArchType ? Constants.loginSource.LX : Constants.loginSource.IK;
  }
  // 爱客登录
  if (hs === Constants.loginHash.IK) {
    return Constants.loginSource.IK;
  }
  // 励销登录
  if (hs === Constants.loginHash.LX) {
    return Constants.loginSource.LX;
  }
  return '';
}

// 文件大小转换
export const calFileSize = (size) => {
  if (size < 1024) {
    return `${(size).toFixed(1)}B`
  }
  if (size >= 1024 && size < 1024 * 1024) {
    return `${(size / 1024).toFixed(1)}KB`
  }
  if (size >= 1024 * 1024 && size < 1024 * 1024 * 1024) {
    return `${(size / (1024 * 1024)).toFixed(1)}MB`
  }
  if (size >= 1024 * 1024 * 1024) {
    return `${(size / (1024 * 1024 * 1024)).toFixed(1)}GB`
  }
}

// 极验数据转换
const gtTransformParams = (params, gtResult) => {
  if (!gtResult) {
    return params
  } else {
    return {
      challenge: gtResult.geetest_challenge,
      validate: gtResult.geetest_validate,
      seccode: gtResult.geetest_seccode,
      ...params
    }
  }
}

// 生成随机hash
function createHash (hashLength) {
  if (!hashLength || typeof(Number(hashLength)) != 'number') {return;}
  const ar = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
  const hs = [];
  const hl = Number(hashLength);
  const al = ar.length;
  for (let i = 0; i < hl; i ++) {
    hs.push(ar[Math.floor(Math.random() * al)]);
  }
  return hs.join('');
}

export const filterRoleList = (rowName, roleList) => {
  return rowName === '超级管理员' ? roleList : roleList?.filter(item => item.name !== '超级管理员')
}


export default {
  walkTree,
  formatLongText,
  isModuleArchType,
  loginSuccessCallback,
  clearLoginData,
  getRedirectUrlForIkAndNewArch,
  getLoginPageByArchType,
  Constants,
  getLoginSource,
  calFileSize,
  gtTransformParams,
  createHash
};
