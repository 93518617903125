import { GET_COMPANY_SUCCESS, GET_CREATOR_INFO_SUCCESS, GET_COMPANY_LIST_SUCCESS, GET_BRAND_INFO_SUCCESS, GET_LOGIN_BRANNER_SUCCESS, GET_LOGIN_BRANNER_ERROR } from '../action-types/company';
import branner from "@assets/images/register/branner.png";
import branner_ik from "@assets/images/register/branner_ik.png";
import { getParameterByName } from '@/utils/qs';

export function companyInfo(state = '', action) {
  if (action.type === GET_COMPANY_SUCCESS) {
    return action.data;
  }
  return state;
}

export function creatorInfo(state = '', action) {
  if (action.type === GET_CREATOR_INFO_SUCCESS) {
    return action.data;
  }
  return state;
}

export function companyList(state = [], action) {
  if (action.type === GET_COMPANY_LIST_SUCCESS) {
    return action.data;
  }
  return state;
}

export function brandInfo(state = [], action) {
  if (action.type === GET_BRAND_INFO_SUCCESS) {
    return action.data;
  }
  return state;
}

// 登录页活动背景 目前默认先取第一个
export function LoginBrannerInfo(state = [], action) {
  let brand = getParameterByName('brand') || 'lixiao'
  if(brand === 'qianfan') {
    brand = 'lixiao'
  }
  const defaultUrl = brand === "ik" ? branner_ik : branner
  if (action.type === GET_LOGIN_BRANNER_SUCCESS) {
    if(!action?.data[0]?.resourceAddress) {
      return [{ resourceAddress: defaultUrl }]
    }
    return action?.data;
  }
  else if(action.type === "GET_LOGIN_BRANNER_ERROR") {
    return [{ resourceAddress: defaultUrl }]
  }
  return state
}

export default {
  companyInfo,
  creatorInfo,
  companyList,
  LoginBrannerInfo
};
