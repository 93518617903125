import LxLogo from '@assets/images/lx-logo.png'
import IkLogo from "@assets/images/ik-logo.png"
import WpsLogo from '@assets/images/wps/wps.png'
import { getParameterByName } from './qs';

// 动态添加meta标签

export function addMeta(name,content){
  let meta = document.createElement('meta');
  meta.content=content;
  meta.name=name;
  document.getElementsByTagName('head')[0].appendChild(meta);
}

// 移动端像素
export function pixel2vw($pixel) {
  return (($pixel / 750) * 100) + 'vw';
}

// 是否是移动端 目前登录-注册-修改密码-试用需要适配移动端样式
export function isMobile() {
  return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
}

export function isWeixin() {
  const ua = navigator.userAgent.toLowerCase();
  const isWeixin = ua?.indexOf('micromessenger') != -1;
  if (isWeixin) {
      return true;
  }else{
      return false;
  }
}

// 修改favicon
export function changeFaviconUrl(brand, logoUrl = "") {
  const favicon =
  document.querySelector('link[rel="shortcut icon"]') || {};
  if(AppConf.jxcHost.ik.includes(location.origin)) {
    if(window?.PLATFORM === "lixiaojxc") {
      favicon.href = LxLogo
      return
    }
    favicon.href = IkLogo
    return
  }
  if(brand === "qianfan") {
    favicon.href = LxLogo;
    return
  }
  else if(brand === "wps") {
    favicon.href = WpsLogo;
  }
  else {
    favicon.href = logoUrl;
  }
}

export const getOS = () => {
  if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    return 'ios'
  }
  if (/(Android)/i.test(navigator.userAgent)) {
    return 'android'
  }
  if (/(Windows NT)/i.test(navigator.userAgent)) {
    return 'windows'
  }
  return 'unknown'
}

export const isWps = () => {
  const os = getOS();
  // eslint-disable-next-line no-undef
  if (
    (os === "android" && window.splash) ||
    (os === "ios" &&
      window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers.handleReq) ||
    (os === "windows" && window.__wpsoffice_environment)
    // window.__wpsoffice_environment
  ) {
    return true;
  }
  return false;
};

export const isQIDIAN = () => {
  return getParameterByName('appToken') === AppConf.appToken.QIDIAN;
}